<script>
import dayjs from "dayjs";
import {warningEventDataViewOpinion, warningEventDataViewTime, warningEventDataViewType} from "@/api/monitor";
import {
  CharsDataMapper2,
  DailyInspectionChars,
  DailyInspectionCharsBiscuit,
  DailyInspectionCharsLine
} from "@/utils/inspectionBoard";

export default {
  name: "monthlyOperation",
  data(){
    const defaultDate = dayjs().format('YYYY-MM')
    return {
      params1: { date: defaultDate, },
      params2: { date: defaultDate, },

    }
  },
  mounted(){
    this.getWarningEventDataViewTime()
    this.getWarningEventDataViewType()
    this.getWarningEventDataViewOpinion()
  },
  methods: {
    /**
     * 总次数
     */
    getWarningEventDataViewTime(){
      warningEventDataViewTime(this.params1).then(res => {
        const xData = []
        const seriesData = []
        Array.isArray(res.data) && res.data.forEach(({ number, time }) => {
          xData.push(`${time}点`)
          seriesData.push(number)
        })
        this.$nextTick(() => {
          const dailyInspectionCharsLine = new DailyInspectionCharsLine('DailyInspection')
          dailyInspectionCharsLine.updateData({ xData, seriesData })
        })
      })
    },
    /**
     * 预警类型
     */
    getWarningEventDataViewType(){
      warningEventDataViewType(this.params2).then(res => {
        if (res.success) {
          this.$nextTick(() => {
            const dailyInspectionChars = new DailyInspectionChars('DailyInspection2')
            const charsDataMapper = new CharsDataMapper2(res.data)
            dailyInspectionChars.updateData(charsDataMapper.get())
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getWarningEventDataViewOpinion(){
      warningEventDataViewOpinion().then(res => {
        if (res.success) {
          const seriesData = []
          Array.isArray(res.data) && res.data.forEach(({ number, name }) => {
            seriesData.push({ value: number, name })
          })
          const dailyInspectionChars = new DailyInspectionCharsBiscuit('DailyInspection3')
          dailyInspectionChars.updateData({ seriesData })
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}
</script>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="chars-container">
          <div class="filter-row">
            <div>预警次数时间分布</div>
            <div class="filter-item-box">
              <el-date-picker v-model="params1.date" value-format="YYYY-MM" type="month" placeholder="选择月"></el-date-picker>
              <el-button class="marginLeft10" type="primary" @click="getWarningEventDataViewTime">确认</el-button>
            </div>
          </div>
          <div class="chars-container-item" id="DailyInspection">

          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="chars-container">
          <div class="filter-row">
            <div>预警类型情况</div>
            <div class="filter-item-box">
              <el-date-picker v-model="params2.date" value-format="YYYY-MM" type="month" placeholder="选择月"></el-date-picker>
              <el-button class="marginLeft10" type="primary" @click="getWarningEventDataViewType">确认</el-button>
            </div>
          </div>
          <div class="chars-container-item" id="DailyInspection2">

          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="marginTop20px">
      <el-col :span="24">
        <div class="chars-container">
          <div class="filter-row">
            <div>预警处理类意见归类</div>
            <div class="filter-item-box"></div>
          </div>
          <div class="chars-container-item" id="DailyInspection3">

          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="scss">
.chars-container{
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 400px;
  background-color: #fff;
  border-radius: 8px;
  text-align: left;
  box-sizing: border-box;

  .filter-row{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    .filter-item-box{
      display: flex;
    }
  }
  .chars-container-item{
    flex: 1;
    //background-color: rgb(62, 96, 112);
  }
}
</style>